<template>
    <el-main class="com-eTrip-section">
        <el-header style="background-color: #fff;padding: 5px 10px;margin-bottom: 10px">
            <el-form size="small" >
                <el-form-item label="订单类型" :inline="true">
                    <el-radio-group v-model="form.productForm" @change="getData">
                        <el-radio-button label="全部"></el-radio-button>
                        <el-radio-button v-for="vm in  getProductFormList" :label="vm.name"
                                         :key="vm.value"></el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </el-header>
        <div class="eTrip-section-cont order-list">
            <ul class="list" v-if="result.total>0">
                <li class="list-item" v-for="vm in result.data" :key="vm.id">
                    <ul class="table-row">
                        <li class="table-column">
                            <div class="text-item">
                                <p class="label">下单时间：</p>
                                <p class="value">{{ vm.createTime | dateCus }}</p>
                            </div>
                        </li>
                        <li class="table-column">
                            <div class="text-item">
                                <p class="label">出行时间：</p>
                                <p class="value">{{ vm.reserveTime | dateCus('yyyy-MM-dd hh:mm') }}</p>
                            </div>
                            <div class="text-item">
                                <p class="label">出发地址：</p>
                                <div class="value">
                                    <p>{{ vm.departure.city }}&nbsp;{{ vm.departure.title }}</p>
                                    <p style="font-size: 12px;color: #999">{{ vm.departure.address }}</p>
                                </div>
                            </div>
                            <div class="text-item" v-for="(item,index) in vm.viaPoints" :key="item.id">
                                <p class="label">{{ item.pointType == 1 ? '上客' : '下客' }}途经{{ index + 1 }}：</p>
                                <div class="value">
                                    <p>{{ item.name }}</p>
                                </div>
                            </div>
                            <div class="text-item">
                                <p class="label">送达地址：</p>
                                <div class="value">
                                    <p>{{ vm.departure.city }}&nbsp;{{ vm.destination.title }}</p>
                                    <p style="font-size: 12px;color: #999">{{ vm.destination.address }}</p>
                                </div>
                            </div>
                            <div class="text-item" v-if="vm.itineraryInfo">
                                <p class="label">行程信息：</p>
                                <div class="value">
                                    <el-popover
                                        placement="left"
                                        title="行程信息"
                                        width="600"
                                        trigger="hover">
                                        <trip-info :info="vm"></trip-info>
                                        <el-link type="primary" slot="reference">查看</el-link>
                                    </el-popover>
                                </div>
                            </div>
                            <div class="text-item">
                                <p class="label">出行人数：</p>
                                <p class="value">{{ vm.playPersonNumber }}人</p>
                            </div>
                            <div class="text-item">
                                <p class="label">出行天数：</p>
                                <p class="value">{{ vm.tripDay }}天</p>
                            </div>
                            <div class="text-item">
                                <p class="label">备注：</p>
                                <p class="value">{{ vm.remark }}</p>
                            </div>
                        </li>
                        <li class="table-column">
                            <div class="text-item">
                                <p class="label">类型：</p>
                                <p class="value">{{ vm.productFormName }}</p>
                            </div>
                            <div class="text-item" v-if="vm.expectedTime">
                                <p class="label">预计时长：</p>
                                <p class="value">
                                    {{
                                        dayjs().startOf('d').add(vm.expectedTime, 'm').format('HH小时mm分钟')
                                    }}</p>
                            </div>
                            <div class="text-item" v-if="vm.expectedDistanceLength">
                                <p class="label">预计公里数：</p>
                                <p class="value">{{ vm.expectedDistanceLength }}公里</p>
                            </div>
                            <div class="text-item">
                                <p class="label">车辆：</p>
                                <p class="value">专车，{{ formatCarModel(vm.vehicleType).name }}</p>
                            </div>
                        </li>
                        <li class="table-column">
                            <div class="text-item" v-if="vm.operatorName">
                                <p class="label">运营商：</p>
                                <p class="value">{{ vm.operatorName }}</p>
                            </div>
                            <div class="text-item">
                                <p class="label">结算金额：</p>
                                <p class="value">¥{{ vm.orderSettleAmount | currency }}</p>
                            </div>
                        </li>
                    </ul>
                    <div class="list-item_buttons">
                        <span>抢单截止时间：{{ vm.expireTime | dateCus }}</span>
                        <el-button :loading="loading" size="small" style="width: 120px;margin-left: 20px" type="primary"
                                   @click="handleGrab(vm)">抢单
                        </el-button>
                    </div>
                </li>
            </ul>
            <el-empty v-else/>
        </div>
    </el-main>
</template>

<script type="text/ecmascript-6">

import {getOrderGrabSupplierListApi, supplierGrabOrderApi} from "@/www/urls/orderUrls";
import {getSeatModel, productFormList} from "../../../../data";
import dayjs from "dayjs";
import {findItem} from "@/common/js/common";
import TripInfo from "@/www/pages/orderManage/components/tripInfo/index.vue";

export default {
    components: {TripInfo},
    //定义模版数据
    data() {
        return {
            productFormList,
            form: {
                productForm: '全部',
                pageIndex: 0,
                pageSize: 10
            },
            result: {
                total: 0,
                data: []
            },
            loading: false
        }
    },
    computed: {
        getProductFormList() {
            return productFormList.filter(({name}) => '短驳接送、接送服务、接机、送机、接站、送站、按日包车'.includes(name))
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        dayjs,
        formatCarModel(type) {
            let info = {};
            if (type) {
                info = getSeatModel(parseInt(type));
            }
            return info;
        },
        async getData() {
            try {
                this.loadingShow();
                const ret = await getOrderGrabSupplierListApi({
                    ...this.form,
                    productForm: this.form.productForm !== '全部' ? findItem(productFormList, this.form.productForm, 'value', 'name') : undefined,
                })
                this.loadingHide();
                if (ret.success && ret.data) {
                    this.result.data = ret.data.map((item) => {
                        item.departure = JSON.parse(item.departure);
                        item.destination = JSON.parse(item.destination);
                        if (item.orderViaPointDtos && item.orderViaPointDtos.length) {
                            item.viaPoints = [...item.orderViaPointDtos]
                            if (`${item.viaPoints[0].lon},${item.viaPoints[0].lat}` === item.departure.location) {
                                item.viaPoints.shift();
                            }
                        }
                        return ({
                            ...item,
                            productFormName: findItem(productFormList, ~~item.productForm, 'name'),
                            orderCustomizeInfoDto: {
                                itineraryInfo: item.itineraryInfo
                            }
                        })
                    });
                    this.result.total = ret.data.length
                } else this.$message.error(ret.errors[0].message)
            } catch (e) {
                console.log(e)
            }
        },
        async handleGrab({id}) {
            try {
                this.loading = true
                await this.$confirm(`<div>
<p>此操作无法撤回，请确认是否抢单</p>
<p>抢单后请尽快安排司机车辆</p>
<p class="color-danger">恶意抢单、扰乱竞争秩序的操作将依据抢单规则相应处罚</p>
</div>`, '请确认抢单', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确认抢单'
                });
                const ret = await supplierGrabOrderApi({
                    grabId: id
                })
                if (ret.success && ret.data) {
                    this.$message.success('抢单成功！')
                    this.getData();
                } else this.$message.error(ret.errors[0].message)
            } catch (e) {
                console.log(e)
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped lang="scss">
.eTrip-section-cont {
    /deep/
    .list-search-before .el-form-item.el-form-item--small {
        width: auto;
    }
}

.list {
    .list-item {
        background-color: #fff;
        margin-bottom: 10px;

        .list-item_buttons {
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-top: 1px solid #eee;
            padding: 10px;
            color: #666;
        }

        .table-row {
            display: flex;

            .table-column {
                flex: 1;
                padding: 10px 16px;

                &:not(:last-child) {
                    border-right: 1px solid #eee;
                }
            }
        }
    }
}

.text-item {
    display: flex;
    margin-bottom: 3px;
    line-height: 1.5em;
    font-size: 14px;

    .label {
        min-width: 80px;
        color: #909399;
    }

    .value {
        color: #333;
        font-weight: 500;
    }
}
</style>
